import React, { Suspense, lazy, useEffect } from 'react'
import { Routes, Route, useRoutes } from "react-router-dom";
import UserRoles from './pages/users/UserRoles';
import ProtectedRoute from './ProtectedRoute';
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { useAppDispatch } from './redux/hooks';
import * as Auth from "./redux/slices/auth.slice";
import Taxes from './pages/tax/Tax';
import Home from './pages/Home';

const Layouts = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const Logout = lazy(() => import('./pages/Logout'));
const Customers = lazy(() => import('./pages/customers/Customers'));
const Users = lazy(() => import('./pages/users/Users'));
const CreateOrder = lazy(() => import('./pages/order/Order'));
const Order = lazy(() => import('./pages/order/Order'));
const OrderDetails = lazy(() => import('./pages/order/OrderDetails'));
const OrderDoc = lazy(() => import('./pages/OrderDoc'));
const NoPage = lazy(() => import('./pages/NoPage'));

interface IProps {
    isFirebaseInitialized: boolean
}

function Router(props: IProps) {
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (props.isFirebaseInitialized) {
            onListenAuthChange();
        }
    }, [props.isFirebaseInitialized])

    const onListenAuthChange = () => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user: User | null) => {
            if (user) {
                const uid = user.uid;
                dispatch(Auth.onLoginSuccess(user));
            } else {
                dispatch(Auth.onLogoutSuccess());
            }
        });
    }


    return (
        <Suspense>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/order/doc/:id" element={<OrderDoc />} />
                <Route path="/order" element={<ProtectedRoute isFirebaseInitialized={props.isFirebaseInitialized} />}>
                    <Route path=":id" element={<OrderDetails />} />
                </Route>
                <Route path="/" element={<ProtectedRoute isFirebaseInitialized={props.isFirebaseInitialized} />}>
                    <Route path="/admin" element={<Layouts />}>
                        <Route index element={<Home />} /> {/* This loads Home when /admin is accessed */}
                        <Route path="logout" element={<Logout />} />
                        <Route path="companies" element={<Customers />} />
                        <Route path="taxes" element={<Taxes />} />
                        <Route path="orders" element={<Order />} />
                        <Route path="users" element={<Users />} />
                        <Route path="order/:id" element={<OrderDetails />} />
                        <Route path="*" element={<NoPage />} />
                    </Route>
                </Route>
                <Route path="*" element={<NoPage />} />
            </Routes>
        </Suspense>
    )
}

export default Router;