import { takeEvery, put } from "redux-saga/effects";
import * as Country from '../slices/country.slice';
import { types } from '@proj/static';
import * as countryServices from '../../services/country.services';

function* getCountries() {
    try {
        let result: types.userTypes.TCountry[] = yield (countryServices.getCountries());
        yield put(Country.getCountriesSuccess(result));
    }
    catch (err) {
        yield put(Country.getCountriesFailed({
            error: err,
            message: "Error Occured"
        }))
    }
}

function* countrySaga() {
    yield takeEvery(Country.getCountries.type, getCountries);
}
export default countrySaga;