import { takeEvery, put } from "redux-saga/effects";
import * as State from '../slices/state.slice';
import { types } from '@proj/static';
import * as stateServices from '../../services/state.services';

function* getStates() {
    try {
        let result: types.userTypes.TState[] = yield (stateServices.getStates());
        yield put(State.getStatesSuccess(result));
    }
    catch (err) {
        yield put(State.getStatesFailed({
            error: err,
            message: "Error Occured"
        }))
    }
}

function* stateSaga() {
    yield takeEvery(State.getStates.type, getStates);
}
export default stateSaga;