import { FirebaseError } from "firebase/app";
import http from "../utils/http";
import { getAuth, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from "firebase/auth";

interface LoginPayload {
    email: string
    password: string
}

interface EmailCheckPayload {
    email: string
}

export const login = async (payload: LoginPayload) => {
    try {
        const auth = getAuth();
        let { email, password } = payload;
        let userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        return user;
    }
    catch (error) {
        throw error;
    }
}

export const logout = async () => {
    try {
        const auth = getAuth();
        let result = await signOut(auth);
        return true;
    } catch (err) {
        console.log("Logout error", err);
        return false;
    }
}

export const checkEmail = async (payload: EmailCheckPayload) => {
    let result = await (await http().post(`/auth/check-email`, payload)).data;
    return result.data;
}

export const getUserDetails = async () => {
    let result = await (await http().get(`/user/user`)).data;
    return result.data;
}

export const onSendPasswordResetEmail = async (email: string) => {
    const auth = getAuth();
    let res = await sendPasswordResetEmail(auth, email);
    return res;
}