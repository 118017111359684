import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { types } from '@proj/static';

type stateState = {
    states: types.userTypes.TState[],
    isLoading: boolean
}

const defaultState: stateState = {
    states: [],
    isLoading: true,
}


export const StateSlice = createSlice({
    name: "state",
    initialState: defaultState,
    reducers: {
        getStates: (state) => {
            return {
                ...state,
                isLoading: true
            }
        },
        getStatesSuccess: (state, action: PayloadAction<types.userTypes.TState[]>) => {
            return {
                ...state,
                states: action.payload
            }
        },
        getStatesFailed: (state, action: PayloadAction<types.commonTypes.HTTP_ERROR>) => {
            return {
                ...state,
                getStatesError: action.payload
            }
        },
    }
})

export const {
    getStates,
    getStatesFailed,
    getStatesSuccess
} = StateSlice.actions;

export default StateSlice.reducer;
