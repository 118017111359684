import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { types } from '@proj/static';

type countryState = {
    countries: types.userTypes.TCountry[],
    isLoading: boolean
}

const defaultState: countryState = {
    countries: [],
    isLoading: true,
}


export const CountrySlice = createSlice({
    name: "country",
    initialState: defaultState,
    reducers: {
        getCountries: (state) => {
            return {
                ...state,
                isLoading: true
            }
        },
        getCountriesSuccess: (state, action: PayloadAction<types.userTypes.TCountry[]>) => {
            return {
                ...state,
                countries: action.payload
            }
        },
        getCountriesFailed: (state, action: PayloadAction<types.commonTypes.HTTP_ERROR>) => {
            return {
                ...state,
                getCountriesError: action.payload
            }
        },
    }
})

export const {
    getCountries,
    getCountriesFailed,
    getCountriesSuccess
} = CountrySlice.actions;

export default CountrySlice.reducer;
